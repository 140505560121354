<template>
    <div class="text-center">
        <div class="jumbotron jumbotron-fluid pt-1 pb-0">
            <div class="row mr-0">
                <!-- left column -->
                <div class="col bg-purple">
                    <div class="container has-width-50 float-right">
                        <div class="row">
                            <div class="col">
                                <div class="valign-parent">
                                    <div class="valign-child">
                                        <h1 class="banner-text mt-2">STAP VOOR STAP NAAR DE BRUGKLAS</h1>
                                    </div>
                                    <div class="valign-child pl-2">
                                        <Arrow v-if="!showText" link="/lessenserie-po"></Arrow>
                                    </div>
                                </div>
                                <div v-if="showText">
                                    <p class="banner-text pr-large">Met 'Stap voor stap naar de brugklas' leren leerlingen
                                        in groep 7/8 over zichzelf, met als doel dat ze op zichzelf leren vertrouwen,
                                        ontdekken wat hun leerstrategie is en hoe ze zichzelf kunnen trainen ter
                                        voorbereiding op de brugklas. En wanneer dit klassikaal gebeurt, leren de kinderen
                                        met én van elkaar. Er is géén goed of fout, iedereen is anders en doet het op zijn
                                        eigen manier.
                                        Vul de rugzak van jouw leerlingen met lessen in ZELFKENNIS, van grote toegevoegde
                                        waarde ter voorbereiding op de stap naar de brugklas.
                                        Je neemt altijd jezelf mee!
                                        <br />
                                        <br />
                                        Neem contact op via <a href="mailto:simone@knapkind.nl">simone@knapkind.nl</a> of bel naar 06-22871587 voor meer informatie en/of bestellen.
                                    </p>
                                    <div class="valign-parent">
                                        <div class="valign-child">
                                            <span class="banner-text">Naar de pagina over de lessen ‘Stap voor stap naar de
                                                brugklas’</span>
                                        </div>
                                        <div class="valign-child">
                                            <Arrow link="/lessenserie-po"></Arrow>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- right column -->
                <div class="col bg-orange">
                    <div class="container has-width-50 float-md-left">
                        <div class="row">
                            <div class="col pl-large">
                                <div class="valign-parent">
                                    <div class="valign-child">
                                        <h1 class="banner-text mt-2">IN DE BRUGKLAS</h1>
                                    </div>
                                    <div class="valign-child pl-2">
                                        <Arrow v-if="!showText" link="/lessenserie-vo"></Arrow>
                                    </div>
                                </div>
                                <div v-if="showText">
                                    <p class="banner-text">In het leven van een brugklasser gebeurt veel. Een nieuw
                                        schoolgebouw, verschillende docenten, een andere manier van werken dan op de
                                        basisschool, het leren-leren en (zelfstandig) plannen, kennismaken met veel jongeren
                                        die nog onbekend zijn, jezelf verhouden ten opzichte van de groep. En daarbij een
                                        lichaam dat gaat veranderen en de ‘puberhormonen’ die hun intrede doen.
                                        Laat leerlingen leren over zichzelf door lessen in zelfkennis te geven. Dat geeft
                                        rust en (zelf)vertrouwen in een turbulente levensfase.
                                        <br />
                                        <br />
                                        Neem contact op via <a href="mailto:simone@knapkind.nl">simone@knapkind.nl</a> of bel naar 06-22871587 voor meer informatie en/of bestellen.
                                    </p>
                                    <div class="valign-parent">
                                        <div class="valign-child">
                                            <span class="banner-text">Naar de pagina over de lessen ‘In de brugklas’</span>
                                        </div>
                                        <div class="valign-child">
                                            <Arrow link="/lessenserie-vo"></Arrow>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- logo button in the middle -->
        <router-link to="/over-knapkind" class="d-none d-sm-none d-md-block">
            <img src="@/assets/logo-knop.png" class="logo-knop-small float-right"
                v-bind:class="{ 'logo-knop-large': showText }" />
        </router-link>
    </div>
</template>

<script>
import Arrow from '@/components/Arrow.vue';

export default {
    data() {
        return {
            styleObject: {
                top: '362px',
                marginLeft: '-50px',
                maxWidth: '86px'
            }
        };
    },
    components: {
        Arrow
    },
    props: {
        showText: Boolean
    }
};
</script>


<style scoped>
.banner-text {
    color: white;
    text-align: left;
    padding-right: 5px;
}

.logo-knop-small {
    position: absolute;
    top: 304px;
    margin-left: -50px;
    max-width: 86px;
    z-index: 9;
}

.logo-knop-large {
    position: absolute;
    top: 304px;
    margin-left: -117px;
    max-width: 220px;
    z-index: 9;
}

.has-width-50 {
    max-width: 570px !important;
}

@media (min-width: 768px) {
    .pr-large {
        padding-right: 90px;
    }
}

@media (min-width: 768px) {
    .pl-large {
        padding-left: 120px;
    }
}

.valign-parent {
    display: table;
}

.valign-child {
    display: table-cell;
    vertical-align: middle;
}

a {
    color: inherit;
    text-decoration: underline;
}
</style>
