<template>
	<div>
		<div class="jumbotron jumbotron-fluid bg-lightblue text-white p-0 mt-5 mb-0 text-left">
			<div class="container">
				<div class="row mb-3">
					<div class="col-md-2">
						<img src="@/assets/LG_FC_KnapKind_witteondergrond.png" class="img-fluid" />
					</div>
				</div>
				<div class="row">
					<div class="col">
						<p>
							<span class="font-weight-bold">KNAPKIND</span>
							<br />Simone Busscher
							<br />Bezoekadres:
							<br />Vriezenveenseweg 2
							<br />7678 VC Geesteren (ov)
						</p>
						<p class="pt-0">
							Postadres:
							<br />Oude Lutkeberg 6
							<br />7678 AK Geesteren (ov)
						</p>
						<p class="pt-2">
							06 22 87 15 87
							<br />
							<a class="text-white" href="mailto:simone@knapkind.nl">simone@knapkind.nl</a>
						</p>
					</div>
					<div class="col">
						<p>
							<span class="font-weight-bold">VEEL GEZOCHT</span>
						</p>
						<ul>
							<li><a class="text-white" href="/lessenserie-po">Naar de brugklas</a></li>
							<li><a class="text-white" href="/lessenserie-vo">In de brugklas</a></li>
							<li>Ontspannen naar het eindexamen</li>
							<li><a class="text-white" href="/kindermassage">Zintuiglijk leren PO</a></li>
							<li><a class="text-white" href="/leertraject">Training - Coaching prof</a></li>
						</ul>
					</div>
					<div class="col">
						<p>
							<span class="font-weight-bold">HANDIG</span>
						</p>
						<ul>
							<li><a class="text-white" href="/downloads2">Gratis downloads</a></li>
							<li><a class="text-white" href="/over-knapkind">Bestellen & contact</a></li>
							<li><a class="text-white" href="/lessenserie-po">Kosten</a></li>
						</ul>
					</div>
					<div class="col">
						<p>
							<a class="text-white" href="/uploads/2019_Privacyverklaring-Simone-Busscher.pdf" target="_blank">Privacy</a>
						</p>
						<p>
							<a class="text-white" href="/disclaimer">Disclaimer</a>
						</p>
						<p>Sitemap</p>
						<p>KVK-nummer: 08197895</p>
					</div>
				</div>
			</div>
		</div>
		<div class="container mt-3 mb-1">
			<div class="row">
				<div class="col">
					<p>© 2024 KnapKind - alle rechten voorbehouden - versie {{ packageVersion }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
   	data() {
		return {
			packageVersion: process.env.PACKAGE_VERSION || '0',
		};
    }
}
</script>

<style scoped>
p {
	font-size: 13px;
}

ul {
    padding-left: 18px;
}

li {
    font-size: 13px;
    /* margin-left: -23px; */
}
</style>